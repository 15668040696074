import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    saveAllList: {},
    gradeId: "",
    givePath: "",
  },
  mutations: {
    updateData(state, data) {
      state.saveAllList = data;
    },
    updateId(state, data) {
      state.gradeId = data;
    },
    updateGivePath(state, data) {
      state.givePath = data;
    },
  },
  actions: {},
  modules: {},
});
