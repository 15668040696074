import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../views/index/index.vue"),
  },
  {
    path: "/newIndex",
    name: "newIndex",
    component: () => import("../views/newIndex/newIndex.vue"),
    redirect: { name: "classStudy" },
    children: [
      {
        path: "/newIndex/classStudy",
        name: "classStudy",
        component: () => import("../views/newIndex/classStudy.vue"),
      },
      {
        path: "/newIndex/classInfo",
        name: "classInfo",
        component: () => import("../views/newIndex/classInfo.vue"),
      },
      {
        path: "/newIndex/homeWorkPage",
        name: "homeWorkPage",
        component: () => import("../views/newIndex/homeWorkPage.vue"),
      },
      {
        path: "/newIndex/testAdmin",
        name: "testAdmin",
        component: () => import("../views/newIndex/testAdmin.vue"),
      },
      {
        path: "/newIndex/questionBank",
        name: "questionBank",
        component: () => import("../views/newIndex/questionBank.vue"),
      },
      {
        path: "/newIndex/stars",
        name: "stars",
        component: () => import("../views/newIndex/stars.vue"),
      },
    ],
  },
  {
    path: "/paperPage",
    name: "paperPage",
    component: () => import("../views/paperPage/paperPage.vue"),
  },
  {
    path: "/doQuestion",
    name: "doQuestion",
    component: () => import("../views/newIndex/doQuestion.vue"),
    redirect: { name: "questions" },
    children: [
      {
        path: "/newIndex/questions",
        name: "questions",
        component: () => import("../components/doQuestion/questions.vue"),
      },
      {
        path: "/newIndex/qJieXi",
        name: "quesqJieXitions",
        component: () => import("../components/doQuestion/qJieXi.vue"),
      },
    ],
  },
  {
    path: "/liveRoom",
    name: "liveRoom",
    component: () => import("../views/newIndex/liveRoom.vue"),
  },
  {
    path: "/openAi",
    name: "openAi",
    component: () => import("../views/newIndex/openAi.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/user.vue"),
  },
  {
    path: "/studyDetail",
    name: "studyDetail",
    component: () => import("../views/studyDetail/detail.vue"),
  },
  {
    path: "/onlineCode",
    name: "onlineCode",
    component: () => import("../views/onlineCode.vue"),
  },
  {
    path: "/homeWork",
    name: "homeWork",
    component: () => import("../views/homeWork/homeWork.vue"),
    redirect: { name: "detail" },
    children: [
      {
        path: "/homeWork/detail",
        name: "detail",
        component: () => import("../views/homeWork/detail.vue"),
      },
      {
        path: "/homeWork/homeSubmit",
        name: "homeSubmit",
        component: () => import("../views/homeWork/homeSubmit.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
