<template>
  <div id="app">
    <div id="nav"></div>
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    // 根据不同路由跳转不同页面
    let hrefHost = window.location.host;
    if (this._isMobile() && hrefHost == "edu.ketang.biz") {
      location.href = "http://edu.ketang.biz/mobile/#/";
    }
    if (this._isMobile() && hrefHost == "edu.kt-mao.com") {
      location.href = "http://edu.kt-mao.com/mobile/#/";
    }
    if (this._isMobile() && hrefHost == "edu.ketang-mao.com") {
      location.href = "http://edu.ketang-mao.com/mobile/#/";
    }
    if (this._isMobile() && hrefHost == "edu.heima-ketang.com") {
      location.href = "http://edu.heima-ketang.com/mobile/#/";
    } else {
      // console.log("pc端");
    }
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|incognito|webmate|bada|nokia|lg|ucweb|skyfire)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
</style>
